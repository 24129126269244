import { log } from 'console'
import ajax, { Format, FormatMessage } from './http'
import { AxiosRequestConfig } from 'axios'

/** 登录 */
type AnonymousUserParam = {
  device_fingerprint: string
  language: string
  ip_address: string
  country: string
}

/** 匿名创建或者登录 */
export function apiAnonymousUserLogin(param: AnonymousUserParam) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/anonymous_users', param)
}

/** 登录 */
type GoogleLoginParam = {
  id_token?: string
  access_token?: string
  device_fingerprint: string
  language: string
  ip_address: string
  country: string
  invite_code: string | null
  official_invite_code: string | null
}

/** google 登录 */
export function apiLoginWithGoogle(param: GoogleLoginParam) {
  // console.log(param, 'apiLoginWithGoogle');

  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/v2/login-with-google', param)
}

/** facebook 登录 */
type FacebookLoginParam = {
  access_token: string
  device_fingerprint: string
  language: string
  ip_address: string
  country: string
  invite_code: string | null
  official_invite_code: string | null
}

/** facebook 登录 */
export function apiLoginWithFacebook(param: FacebookLoginParam) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/facebook-login', param)
}

/** apple 登录 */
export function apiPostUserAppLoginVerifyAppleWeb(param: GoogleLoginParam) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/apple-login', param)
}

/** Microsoft 登录 */
export function apiPostUserAppLoginVerifyMicrosoftWeb(param: GoogleLoginParam) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/microsoft-login', param)
}

/** 上传pdf
 * ProgressCallback 进度监听
 */
interface ProgressCallback {
  (percentCompleted: number): void
}

export function apiUploadPdf(file: File, progressCallback: ProgressCallback) {
  const fd = new FormData()
  fd.append('file', file)
  return ajax.post('api/upload_pdf', fd, {
    onUploadProgress: progressEvent => {
      // 计算上传进度
      if (progressEvent.total !== undefined) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        progressCallback(Math.min(percentCompleted, 99))
        // console.log(`Upload Progress: ${percentCompleted}%`)
      } else {
        // console.log('Upload Progress: Unable to determine total size')
      }
    },
  })
}

/** pdf处理状态列表
 * file? 如何file不为null则为本地上传
 */
export type TaskItemData = {
  pdf_id: number
  pdf_name: string
  status: string
  is_downloadable: boolean
  preview_page_completed: boolean
  file?: File,
  output_type: string
  upload_time: string
  translate_type: string
  page_count: number
  summary_id: number
  progress_value: number
}

export type TaskDetailData = {
  pdf_id: number
  pdf_name: string
  image_name: string
  status: string
  is_downloadable: boolean
  preview_page_completed: boolean
  document_category?: string,
  output_type: string
  upload_time: string
  translate_type: string
  page_count: number
  summary_id: number
  progress_value: number
  progress: string
}

export function apiPdfTasks(translate_type: string, document_category: string) {
  return ajax.get<Format<Array<TaskDetailData>>>(`api/pdf_tasks?translate_type=${translate_type}&document_category=${document_category}`)
}

/** pdf预览图 */
export function apiGetPreview(pdf_id: number) {
  return ajax.get<
    Format<{
      preview_pdf: Array<string>
      preview_png: Array<string>
    }>
  >('api/get_preview?pdf_id=' + pdf_id)
}

/** 单个PDF详情 */
export function apiGetPreviewDetail(pdf_id: string, type: string) {
  return ajax.get<Format<TaskDetailData>>(`/api/v2/get_preview_detail?pdf_id=${pdf_id}/type=${type}`)
}

/** 单张图片详情 */
export function apiImagePreviewDetail(pdf_id: string) {
  return ajax.get<Format<TaskDetailData>>(`/api/get_image_preview_detail?pdf_id=${pdf_id}`)
}

/** 翻译状态 */
export function apiGetTrPreviewDetail(pdf_id: number) {
  return ajax.get<Format<TaskDetailData>>('/api/get_translate_preview_detail?pdf_id=' + pdf_id)
}

/** 获取付款信息 */
export type PaymentInfo = {
  is_remaining_pages: boolean
  payment_gateways: Array<string>
  price_per_page: string
  pricing_code: string
  total_pages: number
  total_price: number
}
export function apiPaymentForPdf(task_id: number, task_type: string, user_language: string, is_translate: boolean, is_pro?: boolean) {
  const config: AxiosRequestConfig = {
    params: {
      // 确保使用 'params' 键来包含所有查询参数
      task_id,
      task_type,
      user_language,
      is_pro,
      is_translate
    },
  }

  // console.log(config, '获取付款信息');
  return ajax.get<Format<PaymentInfo>>('api/calculate_payment', config)
}

/** 创建支付意图 */
type PaymentIntent = {
  pricing_code: string
  payment_methods: Array<string>
  pdf_id: number
  pdf_type: string
}

export function apiCreatePaymentIntent(param: PaymentIntent) {
  // console.log(param, '创建支付意图');
  var data = {};
  var url = '/api/create-payment-intent'
  if (param.pdf_type === 'IMAGE_TO_TEXT') {
    data = {
      pricing_code: param.pricing_code,
      payment_methods: param.payment_methods,
      image_task_summary_id: param.pdf_id
    }
    url = '/api/image_to_pdf/create-payment-intent'
  } else {
    data = {
      pricing_code: param.pricing_code,
      payment_methods: param.payment_methods,
      pdf_id: param.pdf_id
    }
  }
  // console.log(data, 'data');

  return ajax.post<
    Format<{
      client_secret: string
      payment_id: number
    }>
  >(url, data)
}

/** 支付确认 */
export function apiConfirmPayment(payment_intent_id: string, isImage = false) {
  const url = isImage ? '/api/image_to_pdf/confirm-payment' : '/api/confirm-payment';
  // console.log(url, payment_intent_id, isImage);

  return ajax.post<
    Format<{
      amount: number
      currency: string
    }>
  >(url, { payment_intent_id })
}

/** 消耗页数 */
export function apiValidatePageConsumed(pdf_id: number, is_translate: boolean, isImage = false) {
  const url = isImage ? `/api/validate-page-consumed?image_task_summary_id=${pdf_id}` : `/api/validate-page-consumed?pdf_id=${pdf_id}${(is_translate ? `&is_translate=${is_translate}` : '')}`
  return ajax.get(url)
}

/** 删除记录 */
export function apiDeletePdf(pdf_id: number, type: string) {
  return ajax.delete(`/api/delete_pdf?pdf_id=${pdf_id}&type=${type}`)
}




export type UserInfo = {
  account_balance: {
    remaining_pages: Number | string,
    translated_pages: Number | string,
  },

  basic_info: {
    email: string,
    is_password_set: boolean,
    login_type: [string],
    username: string,// 用户名
  }
}

/** 查询用户信息 */
export function getUserInfo() {
  return ajax.get<Format<UserInfo>>('/api/my_account')
}

/** 发送邮件 */
export function sendVerificationCode(receiver: string, language: string) {
  return ajax.post('/api/send_verification_code', { receiver, language })
}

/** 修改密码 */
export function updatePassword(new_password: string, verification_code: string) {
  return ajax.post('/api/update_password', { new_password, verification_code })
}

/** 修改邮箱 */
export function updateEmail(email: string, verification_code: string) {
  return ajax.post('/api/update_email', { email, verification_code })
}

/** 注销用户 */
export function deleteAccount() {
  return ajax.delete('/api/delete_account')
}

/** 更新用户名 */
export function updateUsername(username: string) {
  return ajax.post('/api/update_username', { username })
}

/** 下载PDF */
export function apiDdownloadPdf(pdf_id: number) {
  return ajax.get<
    Format<{
      compiled_file: string
    }>
  >('/api/download_pdf?pdf_id=' + pdf_id)
}

/** 下载markdown */
export function apiDdownloadMarkdown(pdf_id: number) {
  return ajax.get<
    Format<{
      compiled_file: string
    }>
  >('/api/download_markdown?pdf_id=' + pdf_id)
}

/** 下载翻译PDF */
export function apiDdownloadTrPdf(pdf_id: number) {
  return ajax.get<
    Format<{
      compiled_file: string
    }>
  >('/api/download_translate_pdf?pdf_id=' + pdf_id)
}

/** 下载图片 */
export function apiDownloadImage(pdf_id: number) {
  return ajax.get<
    Format<{
      compiled_file: string
    }>
  >('/api/download_image_to_text_result?pdf_id=' + pdf_id)
}

/** 使用优惠码 */
export function apiUsePromoCode(pdf_id: number, promo_code: string) {
  return ajax.post('/api/use-promo-code', { pdf_id, promo_code })
}

/** 开启心跳会话 */
export function apiStartSession() {
  return ajax.post<
    Format<{
      session_id: number
    }>
  >('/api/start_session')
}

/** 心跳监听 */
export function apiHeartbeat(session_id: number) {
  return ajax.post('/api/heartbeat', { session_id })
}

/** seo 文章获取 */
export type ArticleContent = {
  seo_id: number
  index: number
  title: string
  content: string
  image_path: string
  keyword_tag: string
}

export function apiArticleList(activeLanguage: string) {
  return ajax.get<Format<Array<ArticleContent>>>('/api/get-seo?language=' + activeLanguage)
}

/** seo 文章获取内容 */
export function apiArticleGet(keyword_tag: string) {
  return ajax.get<
    Format<{
      content: string
      title: string
      keywords: string
      description: string
    }>
  >('/api/get-seo-by-key?keyword_tag=' + keyword_tag)
}


/** 用户反馈 */
export function feedback(topic: string, feedback_text: string, contact_info: string, likelihood_to_recommend: string, rating: string) {
  // console.log({ topic, feedback_text, contact_info, likelihood_to_recommend });

  return ajax.post('/api/submit_user_feedback', { topic, feedback_text, contact_info, likelihood_to_recommend, rating })
}

/** seo 模糊搜索 */
export type SearchListParams = {
  article_image_names?: Array<string>
  content: string
  index: string
  keywords: string
  keyword_tag: string
  title: string
}

export function searchText(keyword: string) {
  return ajax.get<
    Format<
      SearchListParams[]
    >
  >('api/search-seo-keywords?keyword=' + keyword)
}

/** seo 剩余文章生成次数 */
export function ApiRemainingPages() {
  return ajax.get<
    Format<{
      remaining_pages: number
    }>
  >('/api/my_remaining_pages')
}

/** seo 获取邀请信息 */
export function ApiMyinvitation() {
  return ajax.get<
    Format<{
      invite_code: string
      invite_link: string
    }>
  >('/api/my_invitation')
}

/** 验证邀请码*/
export function apiInvitionVerify(code: string) {
  return ajax.get('api/invition/verify?invitation_code=' + code)
}

/** 检测邮箱 */
export function apiCheckEmail(email: string) {
  return ajax.get<
    Format<{
      has_registered: boolean
    }>
  >('api/check_email?email=' + email)
}

/** 邀请活动 */
export function showActivityPopup() {
  return ajax.get<{ data: { show_popup: boolean } }>('api/show_activity_popup')
}

/** 预览 */
export function imageTasks(id: string) {
  // console.log('api/image_tasks/' + id, '预览');

  return ajax.get('api/image_tasks/' + id)
}

/** 发送邮件 */
export function apiSendEmail(receiver: string, language: string) {
  return ajax.post('api/send_verification_code', {
    receiver,
    language,
  })
}

/** 登录 */
type EmailRegisterParam = {
  device_fingerprint: string
  language: string
  ip_address: string
  country: string
  verification_code: string | null
  invite_code: string | null
  official_invite_code: string | null
  email: string
  password: string
  pdf_id: string
}
/** 邮箱注册 */
export function apiRegisterEmail(params: EmailRegisterParam) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/register_by_email', params)
}

/** 邮箱设置密码 */
export function apiSetPassword(password: string) {
  return ajax.post('api/set_password', { password })
}

/** 邮箱密码登录 */
export function apiPasswordLogin(email: string, password: string) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/login_with_email', { email, password })
}

/**通用埋点接口 */
export function apiTrack(
  page_url: string,
  event_type: string,
  event_data: Object,
  tracking_name: String,
  response_status_code: Number
) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/track', { page_url, event_type, event_data, tracking_name, response_status_code })
}

/** 获取key和密钥*/
export function apiGetKeySecret() {
  return ajax.get('api/pdf/get_company_info')
}

/** 获取b2b测试密钥*/
export function apiGetTestKeySecret() {
  return ajax.get('api/pdf/test-client-token')
}

/**B2B创建付款详情 */
export function apiGetB2bPaymentDetail(pages: string, user_language: string) {
  const config: AxiosRequestConfig = {
    params: {
      // 确保使用 'params' 键来包含所有查询参数
      pages,
      user_language,
    },
  }
  return ajax.get('api/b2b/payment_for_pdf', config)
}

/** b2b支付意图接口 */
type B2bPaymentIntent = {
  pages: string
  pricing_code: string
  payment_methods: Array<string>
}

/**b2b创建支付意图接口 */
export function apiCreateB2bPaymentIntent(param: B2bPaymentIntent) {
  return ajax.post<
    Format<{
      client_secret: string
      payment_id: number
    }>
  >('api/b2b/create-payment-intent', param)
}

/** b2b确认付款成功 */
export function apiConfirmB2bPayment(payment_intent_id: string) {
  return ajax.post('api/b2b/confirm-payment', { payment_intent_id })
}

/**申请发票 */
export function apiApplyBill(param: any) {
  return ajax.post('api/publish_application', param)
}

/** 检测是否有剩余免费次数 */
export function apiCheckFreeAttempt() {
  return ajax.get<Format<{
    used_free_attempt: boolean
  }>>('api/check_free_attempt')
}

/** 获取剩余免费次数 */
export function apiGetFreeCount() {
  return ajax.get<Format<{
    count: number
  }>>('api/get-remaining-free-count')
}


/** 上传PDF V2 */
export function apiUploadPdfV2(file: File, free_attempt: boolean, progressCallback: ProgressCallback) {
  // console.log(file, '上传PDF');

  const fd = new FormData()
  fd.append('file', file)
  if (!free_attempt) {
    fd.append('free_attempt', "true")
  }
  return ajax.post('api/upload_pdf', fd, {
    onUploadProgress: progressEvent => {
      // 计算上传进度
      if (progressEvent.total !== undefined) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        progressCallback(Math.min(percentCompleted, 100))
        // console.log(`Upload Progress: ${percentCompleted}%`)
      } else {
        // console.log('Upload Progress: Unable to determine total size')
      }
    },
  })
}

/** pdf设置更新 */
type PdfSettings = {
  task_id: number
  source_languages: Array<string>
  target_language?: string
  output_formats: Array<string>
}

export function apiUpdatePdfTaskSettings(settings: PdfSettings) {
  // console.log(settings, 'settings');
  return ajax.put('api/update_pdf_task_settings', settings)
}

/** 翻译预览图 */
// 导出一个名为 apiGetTranslatePreview 的函数，该函数用于获取翻译预览
export function apiGetTranslatePreview(pdf_id: number) {
  // 调用 ajax 库的 get 方法，发送一个 GET 请求
  // 请求的 URL 是 'api/get_translate_preview'，并附带查询参数 'pdf_id'
  // pdf_id 是函数的参数，表示要获取翻译预览的 PDF 文件的 ID
  return ajax.get('api/get_translate_preview?pdf_id=' + pdf_id)
}

export type ActivityInfoModel = {
  countdown: {
    end_timestamp: number,
    is_active: boolean
  },
  progress: {
    stage_1: boolean,
    stage_3: boolean,
    stage_7: boolean,
    stage_1_claimed: boolean,
    stage_3_claimed: boolean,
    stage_7_claimed: boolean,
    total_invites: number,
  },
  invite_code: string,
  invite_link: string
};

// 邀请进度以及倒计时信息
export function getActivityInfo() {
  // 调用 ajax 库的 get 方法，发送一个 GET 请求到 'api/invition/activity/info' 接口
  // 该接口用于获取活动信息
  return ajax.get<Format<ActivityInfoModel>>('api/invition/activity/info')
}

export type RecordUserList = {
  invitee_reward: string,
  email: string
  inviter_reward: string,
  created_at: string,
  inviter_reward_en: string,
  invitee_reward_en: string
}

// 获取邀请记录列表
// 导出一个名为 getActivityRecordsList 的函数
export function getActivityRecordsList() {
  // 调用 ajax 库的 get 方法，发送一个 GET 请求到 'api/invition/records' 接口
  // 该接口用于获取邀请活动的记录列表
  return ajax.get<Format<[RecordUserList]>>('api/invition/records')
}

// /
// 导出一个名为 acceptCode 的函数，该函数接受一个字符串类型的参数 invite_code
export function acceptCode(invite_code: string) {
  // 调用 ajax 对象的 post 方法，发送一个 POST 请求到 'api/invition/accept' 接口
  // 请求体包含一个对象，该对象有一个属性 invite_code，其值为传入的 invite_code 参数
  return ajax.post('api/invition/accept', { invite_code })
}

// 导出一个名为 claimReward 的函数，该函数接收一个字符串类型的参数 stage
export function claimReward(stage: number) {
  // 调用 ajax 对象的 post 方法，发送一个 POST 请求到指定的 URL 'api/invition/claim-reward'
  // 请求的 URL 是用于处理领取奖励的接口
  // 请求体包含一个对象，该对象有一个属性 stage，其值为函数的参数 stage
  // 该函数返回 ajax.post 方法的返回值，通常是一个 Promise 对象，用于处理异步请求

  return ajax.post('api/invition/claim-reward', { stage })
}


export function isNewUser() {
  return ajax.get<Format<{ is_new_user: boolean }>>('api/is_new_user')
}

// 
export function validateFreeAttempt(pdf_id: string, translate_type: string) {
  const config: AxiosRequestConfig = {
    params: {
      pdf_id,
      translate_type,
    },
  }
  return ajax.get('api/validate_free_attempt', config)
}

export function getQueueTime(pdf_id: string) {
  const config: AxiosRequestConfig = {
    params: {
      pdf_id,
    },
  }
  return ajax.get<Format<{
    formatted_wait_time: string,
    estimated_wait_time: number,
    page_consumption: number,
    page_ahead: number,
    queue_position: number,
    total_queued_pages: number
  }>>('api/get_queue_time', config)
}


export function isEmailSet() {
  return ajax.get<Format<{ has_email: boolean }>>('api/user/is_email_set')
}

export function notificationEmail(email: string) {
  return ajax.post('api/user/notification_email', { email })
}


export function accountStatus() {
  return ajax.get<Format<{ has_email: boolean, is_anonymous: boolean }>>('api/user/account-status')
}
