import "./App.css";
import { RouterProvider } from "react-router-dom";
import { i18nScope } from "./languages";
import { VoerkaI18nProvider } from "@voerkai18n/react";
import { AuthProvider } from "./components/AuthContext";
import { gapi } from "gapi-script";
import { FileUploadProvider } from "./components/FileUploadContext";
import useIsMobile from "./components/useIsMobile";
import { apiTrack } from "./libs/http/api";
import { useEffect } from "react";

import FloatButtonComponent from "./components/FloatButtonComponent";
import router from "./router/Routes";
import { HelmetProvider } from "react-helmet-async";
import { GoogleOAuthProvider } from '@react-oauth/google';

gapi.load("client:auth2", () => {
  gapi.client.init({
    clientId: process.env.REACT_APP_GOOGLE_KEY,
    plugin_name: "chat",
  });
});

const App: React.FC = () => {
  useEffect(() => {
    window.addEventListener('load', () => {
      const language = localStorage.getItem('selectedLanguage') || 'en'; // 默认英文
      VoerkaI18n.change(language)
    });


    const params = new URLSearchParams(window.location.search);
    if (params.get('from') === 'email') {
      apiTrack(window.location.href, 'Email', { date: new Date().toLocaleDateString() }, '从邮件进入', 200)
    } else {
      apiTrack(window.location.href, 'Click', {}, '打开网页', 200)
    }
  }, [])
  return (
    // <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_KEY ?? ""}>
    //   <HelmetProvider>
    //     {/* 全局翻译 */}
    //     <VoerkaI18nProvider scope={i18nScope} fallback={null}>
    //       <div className="App">
    //         {/* 全局身份认证 */}
    //         <AuthProvider>
    //           {/* 全局文件上传 */}
    //           <FileUploadProvider>
    //             <RouterProvider router={router} />
    //             <FloatButtonComponent />
    //           </FileUploadProvider>
    //         </AuthProvider>
    //       </div>
    //     </VoerkaI18nProvider>
    //   </HelmetProvider>
    // </GoogleOAuthProvider>
    <></>
  );
};

export default App;